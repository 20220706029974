@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  display: flex;
}

.productImg {
  width: 72px;
  height: 57px;
  margin: 0 10px 0 $space-base;
}

.unselectable {
  opacity: 0.5;
}

.productInfo {
  display: flex;
  flex-direction: column;

  strong {
    margin-bottom: 6px;
    font-size: $font-size-sm;
  }
}

.productAttributes {
  font-size: $font-size-xs;
}

.notEligible {
  font-weight: $font-weight-bold;
  color: $color-not-eligible;
  font-size: $font-size-sm;
  margin-top: $space-xxxs;
}
