@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: $header-height;
  background: $color-header-checkout-background;
  padding: 0 $space-sm;
  border-bottom: $checkout-header-border;

  @include phone {
    padding: 0 $space-xxxs;
    justify-content: center;
  }

  @include tablet {
    padding: 0 $space-md;
  }

  @include desktop {
    padding: 0 $space-xxl;
  }
}

.checkoutHeader {
  display: flex;
  align-items: center;
  padding-top: 6px;
  margin-left: $space-xs;
}

:is(body) .logo {
  display: flex;
  align-items: center;
  color: $color-header-checkout-text;
}

.logoText {
  margin-left: 3px;
  font-size: $font-size-base;
  font-family: $heading-font;
  letter-spacing: $letter-spacing-headline;
  margin-top: $space-xxxs;
}

.notificationsBannerWrapper {
  position: sticky;
  top: 0;
  z-index: 1;
}
