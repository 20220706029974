@use "@/styles/variables" as *;

.basicNotification {
  display: flex;
  align-items: center;

  > .imageContainer {
    height: 100%;
    width: auto;

    margin-right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  > .textContainer {
    flex: 1;
    font: 15px/18px 500 $body-font;

    display: flex;
    flex-direction: column;

    > .title {
      font-weight: $font-weight-bold;
      line-height: 20px;
    }

    > .linksContainer {
      margin-top: 7px;
      display: flex;

      .link {
        margin-right: 5px;
        color: $color-black;
      }
    }
  }
}
