@use "@/styles/variables" as *;

.modalWrapper {
  max-width: 360px;
}

.body {
  padding: $space-xs 25px 25px;
  line-height: 1.4;
}

.modalHeader {
  background-color: $color-white-gray;
  text-transform: uppercase;
  font-weight: $font-weight-bold;

  h3 {
    margin: 0;
  }
}

.exchangeValue {
  padding-left: 5px;
}

.exchangeButton {
  width: 100%;
}

.cancelButton {
  padding-top: 10px;
  font-weight: $font-weight-bold;
  margin: 0 auto;
  text-align: center;
  display: inherit;
}
