@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss";

.logo {
  img {
    filter: invert(1);
    margin-right: $space-md;
  }
}

.logoText {
  padding-top: 0;
  margin-top: 0;
}
