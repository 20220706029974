@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/utils" as *;

.container {
  position: relative;
}

.block {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  @include md-min {
    flex-direction: row;
  }
}

.text {
  padding: 0 $space-md;
  text-align: center;
  max-width: 80%;
}

.button {
  width: auto;
  padding: 0 $space-md;
}
