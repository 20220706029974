@use "@/styles/variables" as *;

.title {
  display: flex;
  align-items: center;

  span {
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
  }
}

.icon {
  margin-right: $space-xxs;
}

.text {
  margin: 0 0 40px;
  line-height: $line-height-relaxed;
  font-size: $font-size-base;
}

.link {
  font-weight: $font-weight-bold;
  font-size: $font-size-xs;
}
