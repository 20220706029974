@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/utils" as *;

.container {
  display: flex;
  flex-wrap: wrap;
}

.heroTextSliderContainer {
  width: 100%;
  height: auto;

  @include md-min {
    width: 40%;
  }
}

.heroImageSliderContainer {
  width: 100%;

  @include md-min {
    width: 60%;
  }
}

.flip {
  .heroTextSliderContainer {
    order: 0;

    @include md-min {
      order: 1;
    }
  }
}
