@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.input {
  label {
    margin-top: 16px;
    margin-bottom: 8px;
  }
}

.tooltipBody {
  line-height: 1.36;
  margin: 0;
  font-size: $font-size-sm;

  .privacyPolicyLink {
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    color: inherit;
  }
}

.bottomWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.button {
  flex: 1;
}
