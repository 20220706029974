@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  h2 {
    font-family: $heading3-font;
    font-size: $font-size-sm;
    margin: 0 0 $space-sm;
  }
}

.trackButton {
  margin-top: 30px;
}

.product {
  display: flex;
}

.productImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 76px;
  margin-right: $space-xs;
  transition: all 0.5s;

  @include lg-min {
    width: 92px;
  }
}

.productInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    font-size: $font-size-xs;
    margin-bottom: 6px;
  }

  button {
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
  }
}

.totalsWrapper {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $color-content-border;
  margin-top: $space-base;
  padding-top: 10px;
}

.column {
  display: flex;
  flex-direction: column;
}

.columnTitle {
  margin-bottom: 6px;
}

.right {
  text-align: right;
}
