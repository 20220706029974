@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.main {
  flex: 1;
  margin: $space-md 0 50px;
}
