@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.steps {
  display: flex;
  list-style: none;

  @include phone {
    display: none;
  }

  li {
    display: flex;
    align-items: baseline;
    font-size: $font-size-base;
    font-family: $heading-font;
    letter-spacing: $letter-spacing-headline;
    text-transform: uppercase;
    color: $color-header-checkout-text;
    margin-top: $space-xxxxs;
  }

  li + li {
    margin-left: $space-lg;
  }
}