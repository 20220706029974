@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

// Mixins
@mixin base {
  position: relative;
  padding: 0;
  font-size: 1rem;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

@mixin block {
  border-radius: 2px;
  font-weight: $font-weight-bold;
  font-size: 15px;
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0.5rem;

  svg {
    align-self: center;
  }
}

@mixin uppercase {
  text-transform: uppercase;
  letter-spacing: $letter-spacing-headline;
  padding-top: 2px;

  svg {
    margin-top: -2px;
  }
}

@mixin primary {
  color: $color-light;
  background-color: $color-primary-dark;
  border: 1px solid $color-primary-dark;

  .spinner svg {
    color: $color-light;
  }

  &:hover,
  &.hover {
    background-color: $color-light;
    color: $color-dark;

    .spinner svg {
      color: $color-dark;
    }
  }

  &.disabled:hover,
  &.disabled.hover {
    background-color: $color-gray-3;
    border-color: $color-gray-3;
    color: $color-light;
    cursor: auto;

    .spinner svg {
      color: $color-light;
    }
  }
}

@mixin secondary {
  color: $color-primary-dark;
  background-color: $color-light;
  border: 2px solid $color-primary-dark;

  .spinner svg {
    color: $color-dark;
  }

  &:hover,
  &.hover {
    background-color: $color-primary-dark;
    color: $color-light;

    .spinner svg {
      color: $color-light;
    }
  }

  &.disabled:hover,
  &.disabled.hover {
    background-color: $color-light;
    border-color: $color-gray-3;
    color: $color-gray-3;
    cursor: auto;

    .spinner svg {
      color: $color-gray-3;
    }
  }
}

@mixin tertiary {
  color: $color-black-gray;
  background-color: $color-white;
  border: 1px solid #333;

  .spinner svg {
    color: $color-black-gray;
  }

  &:hover,
  &.hover {
    border-color: $color-primary-dark;
    color: $color-primary-dark;

    .spinner svg {
      color: $color-primary-dark;
    }
  }

  &.disabled:hover,
  &.disabled.hover {
    background-color: $color-light;
    border-color: $color-lighter-gray;
    color: $color-lighter-gray;
    cursor: auto;

    .spinner svg {
      color: $color-lighter-gray;
    }
  }
}

@mixin danger {
  color: $color-light;
  background-color: $color-danger-darker;
  border: 1px solid $color-danger-darker;

  .spinner svg {
    color: $color-light;
  }

  &:hover,
  &.hover {
    background-color: $color-light;
    color: $color-danger-darker;
  }

  &.disabled:hover,
  &.disabled.hover {
    background-color: $color-danger-light;
    border-color: $color-danger-light;
    color: $color-light;
    cursor: auto;

    .spinner svg {
      color: $color-light;
    }
  }
}

@mixin link {
  background: transparent;
  text-decoration: underline;
  border: 0;
  color: inherit;
}

@mixin decorative-link {
  background: transparent;
  font-weight: $font-weight-bold;
  border: 0;
  color: inherit;
  text-transform: uppercase;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $color-black;
    transform: scaleX(0%);
    transform-origin: left;
    transition: transform 1s;
  }

  &:hover,
  &.hover {
    &::after {
      content: "";
      transform: scaleX(100%);
    }
  }
}

@mixin hand-drawn-circle {
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 8px 16px;
  outline: none;
  background: transparent;
  font-weight: $font-weight-bold;
  border: 0;
  color: inherit;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  path {
    stroke: $color-primary;
    stroke-width: $font-size-xs;
    stroke-dasharray: 0 1500;
  }

  &:where(:hover, :focus) {
    color: $color-primary;

    path {
      animation: draw 1s forwards;
    }
  }
}

@keyframes draw {
  from {
    stroke-dasharray: 0 1500;
  }

  to {
    stroke-dasharray: 1500 1500;
  }
}

@mixin text {
  background: transparent;
  text-decoration: none;
  border: 0;
  color: inherit;
}

@mixin transparent-light {
  background: transparent;
  border: 1px solid $color-light;
  color: $color-light;

  &:hover,
  &.hover {
    background: $color-light;
    color: $color-dark;
  }

  &.disabled:hover,
  &.disabled.hover {
    background: $color-light;
    color: $color-light;
    cursor: auto;
  }

  .spinner svg {
    color: $color-light;
  }
}

@mixin transparent-dark {
  background: transparent;
  border: 1px solid $color-dark;
  color: $color-dark;

  &:hover,
  &.hover {
    background: $color-dark;
    color: $color-light;
  }

  &.disabled:hover,
  &.disabled.hover {
    background: $color-dark;
    color: $color-dark;
    cursor: auto;
  }

  .spinner svg {
    color: $color-light;
  }
}

/** A button with the brand primary color, which turns white on hover. **/
.primary {
  @include base;
  @include block;
  @include uppercase;
  @include primary;
}

/** A button with white interior and brand border that turns the brand color on hover. **/
.secondary {
  @include base;
  @include block;
  @include uppercase;
  @include secondary;
}

/** A white button with a gray border that turn to the brand color on hover. **/
.tertiary {
  @include base;
  @include block;
  @include tertiary;
}

/** A red button that turns white on hover. **/
.danger {
  @include base;
  @include block;
  @include uppercase;
  @include danger;
}

/**
  * A button that is transparent with a light border that turns light on hover.
  * Used on dark backgrounds.
  **/
.transparentLight {
  @include base;
  @include block;
  @include uppercase;
  @include transparent-light;
}

/**
  * A button that is transparent with a dark border that turns dark on hover.
  * Used on light backgrounds.
  **/
.transparentDark {
  @include base;
  @include block;
  @include uppercase;
  @include transparent-dark;
}

/** Standard link styling without colors or borders.  **/
.link {
  @include base;
  @include link;
}

/** A more festive link with an underline bar that goes from left to right. **/
.decorativeLink {
  @include base;
  @include decorative-link;
}

.handDrawnCircle {
  @include base;
  @include hand-drawn-circle;
}

/** Plain text without underline. **/
.text {
  @include base;
  @include text;
}

.fullWidth {
  display: flex;
  width: 100%;
}

.primary,
.secondary,
.tertiary,
.danger,
.transparentDark,
.transparentLight, {
  @include button-transition;

  &:where(.sizeSm) {
    height: 26px;
  }

  &:where(.sizeMd) {
    height: 36px;
  }

  &:where(.sizeLg) {
    height: 50px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.spinner {
  height: 80%;
}
