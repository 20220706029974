@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.trackingContainer {
  width: 100%;

  .trackingLabel {
    display: flex;
  }

  .trackingNumber {
    display: flex;
    text-decoration: underline;
  }
}

.shipmentEvent {
  line-height: 1.4;
  font-size: $font-size-sm;
}
