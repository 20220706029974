@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.title {
  h1 {
    text-transform: capitalize;
  }
}

.optionTitle {
  font-size: $font-size-sm;
  line-height: 21px;
  font-weight: $font-weight-bold;
  font-family: $body-font;
  margin: $space-base 0 $space-sm;
}

.radioWrapper {
  font-size: 15px;

  + & {
    margin-top: $space-xs;
  }

  input[type="radio"] {
    appearance: none;
    background-color: $color-white;
    margin: 0;
    font: inherit;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid $color-primary;
    margin-right: 10px;
    position: relative;
    top: 1px;

    &::before {
      content: "";
      width: 14px;
      height: 14px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em $color-primary;
    }

    &:checked {
      border: 5px solid $color-primary;

      &::before {
        transform: scale(1);
      }
    }
  }
}

.selectButton {
  margin-top: 10px;
}

.pl30 {
  padding-left: 30px;
}
