@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.button {
  width: 100%;
  flex: 1;
  line-height: 36px;
}

.icon {
  position: relative;
  top: 6px;
}
