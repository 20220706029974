////
/// Defines color variables shared between components without crossing component boundaries.
/// --
/// A handful of UI elements and components are stylistically dependent on one another. In these cases,
/// component-specific variables must be shared with other components, but without violating the component
/// boundary and introducing a dependency cycle. Moreover, it's also sometimes useful to export variables
/// to TypeScript, to use as a prop to another customizable component. We call these types of variables
/// "contextual colors", and they should be defined (and exported if needed) from this file.
///
/// @see Tier 1 in `docs/guides/brand-styling.md` for the theming guide.
/// @group variables/contextualColors
/// --
/// SUGGESTED NAMING CONVENTION:
/// $color-<owner_component>-<owner_attribute>: <value> !default;
/// EX:
/// $color-order-summary-title-foreground: $color-primary !default;
////

@use "./baseColors" as *;

@forward "@/base/styles/variables/colors/contextualColors" with (
  // Uncategorized
  $color-border: $color-black,

  // Variation Panel
  $carousel-navigation: $color-black,
  $carousel-navigation-background: $color-white,

  // Header
  $color-header-background: $color-white,
  $color-header-foreground: $color-primary-darker,
  $color-header-foreground-hover: $color-primary-darker,
  $color-header-border: $color-primary-darker,
  $color-header-logo: $color-primary-darker,
  $color-header-cart-badge-background: $color-tertiary-500,
  $color-header-checkout-background: $color-primary-500,
  $color-header-checkout-text: $color-white,
  $checkout-header-border: 0,

  // Navigation
  $color-navigation-border: $color-primary,
  $color-navigation-background: $color-primary,
  $color-navigation-dim-background: $color-primary-dark,
  $color-navigation-foreground: $color-white,
  $color-navigation-mobile-background: $color-primary,

  // Footer
  $color-footer-background: $color-secondary,

  // Shipping Methods
  $color-holiday-messaging: $color-success-600
);
