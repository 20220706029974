@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/helpers/content" as *;
@use "@/styles/utils" as *;

// Brand adjustable values
$scroll-effect-font-family: $heading-font !default;
$scroll-effect-font-weight: $font-weight-regular !default;
$scroll-effect-font-size: $font-size-xxxl !default;
$scroll-effect-font-size-desktop: $font-size-xxxxxl !default;
$scroll-effect-line-height: 1 !default;
$scroll-effect-color: $color-white !default;
$scroll-effect-background: $color-black !default;
$scroll-effect-gradient: linear-gradient($color-shading-900, $color-shading-100, $color-shading-900) !default;
$scroll-effect-max-width: 50rem !default;
$scroll-effect-padding: 3em $space-md !default;

// Outer container for our scroll effect. This serves as the
// scroll distance element, with the content inside it set
// as sticky.
.scrollEffect {
  // Override container margins and sizing, so we can apply a
  // bg color to this element.
  display: block !important;
  margin: 0 !important;
  max-width: none !important;
  height: 250vh;
}

.scrollEffectInnerContainer {
  height: 100vh;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  font-family: $scroll-effect-font-family;
  font-weight: $scroll-effect-font-weight;
  font-size: $scroll-effect-font-size;
  line-height: $scroll-effect-line-height;
  color: $scroll-effect-color;
  background: $scroll-effect-background;

  // Override container margins and sizing, so we can apply a
  // bg color to this element.
  margin: 0 !important;
  max-width: none !important;

  // Use the enclosed .block element to apply container styles.
  .block {
    max-width: $scroll-effect-max-width;
    padding: $scroll-effect-padding;
    margin: auto;
    background: $scroll-effect-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200vw 100vh;
    background-position: 0% 0%;
  }

  @include lg-min {
    font-size: $scroll-effect-font-size-desktop;
  }
}

@mixin large-headline {
  .block {
    font-family: $heading2-font;
    font-size: $font-size-xxxxxl;

    p {
      margin: 0 0 $space-md 0;
    }
  }
}

.largeHeadline {
  @include large-headline;
}

.container {
  display: flex;
  flex-direction: column;
  margin: $space-md auto;
  margin-bottom: 0;
  width: 100%;
}

.container:has(.overlay) {
  margin: 0;
}

.headline {
  font-family: $heading-font;
  letter-spacing: $letter-spacing-headline;
  text-transform: uppercase;
  margin: $space-xxs 0;
}

.block {
  margin: 0 $space-md;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
}

.row {
 display: contents;

  button {
    margin: 0 $space-xs;
  }
}

// This will ensure that that adjacent text take on the same color as the link
.link:where(:hover,:focus) {
  cursor: pointer;
  color: $color-primary;
}

.center {
  align-items: center;
  text-align: center;

  .buttonContainer {
    align-items: center;
  }
}

.left {
  margin-left: auto;
  align-items: center;
  text-align: center;

  .block {
    margin: 0 $space-md;
  }

  .buttonContainer {
    margin-right: auto;
    align-items: center;
  }

  @include md-min {
    align-items: flex-start;
    text-align: left;

    .block {
      margin-left: 0;
    }

    .buttonContainer {
      margin-left: 0;
      align-items: flex-start;
    }
  }
}

.right {
  margin-right: auto;
  align-items: center;
  text-align: center;

  .block {
    margin: 0 $space-md;
  }

  .buttonContainer {
    margin-right: 0;
    align-items: center;
  }

  @include md-min {
    margin-right: auto;
    align-items: flex-end;
    text-align: right;

    .block {
      margin-right: 0;
    }

    .buttonContainer {
      margin-right: 0;
      align-items: flex-end;
    }
  }
}

.ctaItem {
  margin-bottom: $space-sm;
}

.overlayMobile {
  @include phone {
    aspect-ratio: 4/3;
    margin: $space-md $space-lg;
    justify-content: center;
    gap: $space-sm;

    .block {
      margin: 0;

      > div:first-child {
        margin: 0;
        margin-bottom: $space-md;

        p {
          margin-top: 0;
          margin-inline: auto;
          margin-bottom: 0;
        }
      }
    }
  }
}

.active path {
  stroke-dasharray: 1500 1500;
}

.overlay {
  margin: $space-lg;
  width: calc(100% - $space-lg * 2);
  height: calc(100% - $space-lg * 2);
  justify-content: center;
  gap: $space-sm;

  h3 {
    margin-bottom: 0;
  }

  .block {
    margin: 0;

    > div:first-child {
      margin: 0;
      min-height: var(--min-height, 200px);
      margin-bottom: $space-md;

      p {
        margin-top: 0;
        margin-inline: auto;
        margin-bottom: 0;
      }
    }
  }

  @include md-min {
    justify-content: flex-end;
    background-color: transparent;
    gap: 0;
    height: calc(100% - $space-sm * 2);
    padding-bottom: 0;

    h3 {
      padding-top: clamp(2rem, 1vw, $space-lg);
    }

    .block {
      width: 100%;
    }
  }
}
