@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  width: 100%;
}

.steps {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.step {
  height: 10px;
  background-color: $color-white;

  &.success {
    border: 1px solid $color-primary;
  }

  &.warning {
    border: 1px solid $color-warning-500;
  }

  &.danger {
    border: 1px solid $color-danger-500;
  }
}

.active {
  &:not(:first-child) {
    border-left: 1px solid $color-white;
  }

  &.success {
    background-color: $color-primary;
    border-top: 1px solid $color-primary;
    border-bottom: 1px solid $color-primary;
  }

  &.warning {
    background-color: $color-warning-500;
    border-top: 1px solid $color-warning-500;
    border-bottom: 1px solid $color-warning-500;
  }

  &.danger {
    background-color: $color-danger-500;
    border-top: 1px solid $color-danger-500;
    border-bottom: 1px solid $color-danger-500;
  }
}
