@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/helpers/content" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss" with (
  $scroll-effect-font-weight: $font-weight-xthin,
  $scroll-effect-font-size: 60px,
  $scroll-effect-font-size-desktop: 86px,
  $scroll-effect-padding: 1.5em $space-md,
  $scroll-effect-gradient: linear-gradient(115deg, #7EA8AF 20%, #D5D7BC 45%, $color-white, #D5D7BC 50%, #7EA8AF 60%, #7EA8AF 80%),
);

.overlay {
  margin: $space-md $space-lg;
  width: calc(100% - $space-lg * 2);
  height: calc(100% - $space-lg * 2);
  gap: 0;

  h3 {
    margin-bottom: 0;
  }

  .block {
    margin: 0;

    > div:first-child {
      margin: 0;
      min-height: var(--min-height, 200px);
      margin-bottom: $space-md;

      p {
        margin-top: 0;
        margin-inline: auto;
        margin-bottom: 0;
      }
    }
  }
}

.left {
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;

  .block {
    margin: 0;
  }

  .buttonContainer {
    align-items: flex-start;
  }
}

// Container modifier for when the TextBlock is used within
// a clickable block element with overlay class assignment.
.clickableBlockElement:where(.overlay) {
  max-width: 30ch !important;
  justify-content: flex-start !important;
  align-items: flex-start;
  text-align: left;

  .headline {
    font-weight: $font-weight-regular;
    font-size: $font-size-lg;
    text-transform: none;
    margin: 0 0 $space-sm 0;
    padding-top: $space-sm;
  }
}

// Container modifier for when the TextBlock is used within
// a clickable block element.
.clickableBlockElement:where(:not(.overlay)) {
  align-items: flex-start;
  margin: $space-lg $space-lg 0 $space-lg;
  width: auto;

  .headline {
    font-weight: $font-weight-regular;
    font-size: $font-size-xxl;
    text-transform: none;
    margin: 0;
    color: $color-secondary-600;
  }

  .block {
    margin: 0;

    p {
      margin: $space-xxs 0;
    }
  }
}
