@use "@/styles/variables" as *;

.container {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: $space-md 0;
  align-self: stretch;
}
