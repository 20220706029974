@use "@/styles/variables" as *;

/**
Clip path defines a part of an element that should be shown.
It is used here to make sure the link is visually hidden to users,
but still visible to screen readers.
Resources:
See: https://www.w3.org/WAI/WCAG22/Techniques/css/C7.html for further context on this technique.
See: https://webaim.org/techniques/css/invisiblecontent/ for context related to invisible content.
See: https://developer.mozilla.org/en-US/docs/Web/CSS/clip-path for CSS docs.
*/

@mixin visually-hidden {
  border: 0;
  clip-path: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  color: $color-primary;
  position: absolute;
  width: 1px;
}
