@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.link {
  @include visually-hidden;
}

.link:focus {
  position: relative;
  clip: auto;
  width: auto;
  height: auto;
  padding: 5px;
  overflow: auto;
  margin: 0;
}
