@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/utils" as *;

.container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
}

.flip {
  .heroText {
    order: 0;

    @include md-min {
      order: 1;
    }
  }
}

.heroImage {
  width: 100%;
}

.bottomContentSlim {
  .heroText {
    height: auto;
  }

  .heroText,
  .textBlock {
    padding: 0;

    @include md-min {
      width: 55%;
    }

    .block {
      @include md-min {
        margin-left: $space-xxxl;
      }
    }
  }

  .heroImage,
  .mainImage {
    height: auto;

    @include md-min {
      width: 45%;
    }
  }
}

.heroBanner {
  .heroText {
    height: auto;

    @include md-min {
      width: 40%;
    }
  }

  .heroImage {
    @include md-min {
      width: 60%;
    }
  }
}

.bottomBannerOverlay {
  flex-wrap: nowrap;

  .heroText {
    z-index: 2;
  }

  .heroImage {
    > div {
      height: 100%;
      object-fit: cover;
    }

    @include phone {
      position: absolute;
      z-index: 1;
    }
  }
}
