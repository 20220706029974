@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.order {
  display: flex;
  flex-direction: column;
  margin-bottom: $space-sm;

  span {
    margin-bottom: $space-xxxs;
  }
}

.subtitle {
  font-size: $font-size-sm;
  margin: 0 0 $space-lg;
  font-family: $body-font;
  line-height: 21px;
}

.productCheckbox {
  display: flex;
  align-items: center;

  & + & {
    margin-top: 30px;
  }
}

.checkbox {
  input[type="checkbox"] {
    appearance: none;
    background-color: $color-white;
    margin: 0;

    width: 14px;
    height: 14px;
    border: $space-xxxxs solid $color-primary;

    display: grid;
    place-content: center;

    &::before {
      content: "";
      width: 14px;
      height: 14px;
      margin-top: 1px;
      clip-path: polygon(17% 54%, 28% 43%, 38% 54%, 70% 22%, 81% 33%, 38% 75%, 17% 54%);
      transform: scale(0);
      transform-origin: bottom left;
      box-shadow: inset 14px 14px $color-primary;
    }

    &:checked::before {
      transform: scale(1);
    }

    &:focus {
      outline: $space-xxxxs solid $color-primary;
      outline-offset: $space-xxxxs;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.continueButton {
  margin-top: 10px;
}
