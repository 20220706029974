@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.image {
  width: 92px;
  height: 76px;
  margin-bottom: $space-xxxs;
  margin-right: $space-xs;
  border-radius: $space-xxxs;
}

.text {
  width: 150px;
  height: 20px;
  margin-bottom: $space-xxxs;
  border-radius: $space-xxxs;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.mb16 {
  margin-bottom: $space-sm;
}
