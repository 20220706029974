@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.stepButton {
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-color: transparent;
  border: 0;
  color: $color-header-checkout-text;
  font-family: Balboa, sans-serif;
  font-size: $space-sm;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.step {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  border: 2px solid $color-header-checkout-text;
  border-radius: 50%;
  margin-right: $space-xs;

  span {
    letter-spacing: 0.5px;
    margin-top: $space-xxxxs;
  }
}

.currentStep {
  background-color: $color-header-checkout-text;
  color: $color-header-checkout-background;
}
