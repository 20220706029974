@use "@/styles/variables" as *;
@use "@/styles/utils/transitions" as *;

@forward "./styles.base.module.scss";

/** Button overrides **/
.primary,
.secondary,
.tertiary,
.danger,
.transparentLight,
.transparentDark {
  min-width: 120px;
  text-transform: uppercase;
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;
  letter-spacing: 2px;
  border-radius: 0;
}

.primary {
  background-color: $color-black;
  border: 1px solid $color-secondary-300;
}

.tertiary {
  border-radius: 0;
  color: $color-black;
  background-color: $color-secondary-300;
  border: 1px solid $color-secondary-300;

  .spinner svg {
    color: $color-black;
  }

  &:hover,
  &.hover {
    background-color: $color-black;
    border-color: $color-black;
    color: $color-white;

    .spinner svg {
      color: $color-white;
    }
  }

  &.disabled:hover,
  &.disabled.hover {
    background-color: $color-gray-3;
    border-color: $color-gray-3;
    color: $color-light;
    cursor: auto;
  }
}
