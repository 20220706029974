@use '@/styles/variables' as *;

// NOTE: See the MDX for a detailed explanation of how this works.

// Dark mode colors
$start-color-dark: rgba(
  $color: $color-black,
  $alpha: 0.03
);
$end-color-dark: rgba(
  $color: $color-black,
  $alpha: 0.05
);

// Light mode colors
$start-color-light: rgba(
  $color: $color-white,
  $alpha: 0.03
);
$end-color-light: rgba(
  $color: $color-white,
  $alpha: 0.05
);

// Animation options here for easier reference
@mixin animation-options {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

// Skeleton loader container. Takes all the space available,
// so give it a CSS class/id or wrap it to control its dimensions.
.skeletonLoader {
  width: 100%;
  height: 100%;
  min-height: 10px;

  position: relative;

  overflow: hidden;
}

@keyframes translate-repeat {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-75%);
  }
}

.innerDecoration {
  // The decoration will be 4x the width of the skeleton loader container.
  width: 400%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  animation-name: translate-repeat;

  @include animation-options;

  // Default background is a gradient with the
  // darker colors
  background: linear-gradient(
    90deg,
    $start-color-dark 0%,
    $start-color-dark 25%,
    $end-color-dark 50%,
    $start-color-dark 75%,
    $start-color-dark 100%
  );

  &.light {
    // If the `light` class is added, use
    // a gradient with lighter colors
    background: linear-gradient(
      90deg,
      $start-color-light 0%,
      $start-color-light 25%,
      $end-color-light 50%,
      $start-color-light 75%,
      $start-color-light 100%
    );
  }
}
