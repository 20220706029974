@use "@/styles/utils" as *;

.title {
  h1 {
    text-transform: capitalize;
  }
}

.line {
  display: block;

  & + & {
    margin-top: 10px;
  }
}
